//
// user.scss
// Use this to write your custom SCSS
//

.cursor-pointer {
  cursor: pointer;
}

.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:hover, .badge-primary {
  color: $white;
}

.list-group-item.active {
  z-index: auto;
}

.modal-full-height {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0
  }

  .modal-content {
    height: 100vh
  }

  .modal-body {
    overflow-y: auto;
  }
}

.table-hover tbody tr:hover {
  cursor: pointer;
}

.icon-left {
  position: relative;
  top: 2px;
  right: 5px;
}

.icon-right {
  position: relative;
  top: 2px;
  left: 5px;
}

.form-control:disabled {
  background-color: #e9ecef;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  & .alert {
    border-radius: 0;
  }
}

@media (min-width: 768px) {
  .fixed-top {
    left: 230px;
  }
}

.modal {
  z-index: 9999;
}

.card-photo-article img {
  max-height: 200px;
  width: auto !important;
}

.btn-group.spacing > .btn {
  border-radius: $input-border-radius !important;
}

.navbar-vertical .navbar-nav .nav-item {
  padding-bottom: .125em;
}

.navbar-nav .nav-link > .fe {
  padding-bottom: 0 !important;
}

.navbar-vertical .navbar-nav .nav-link.active {
  background: #EEB540;
  // color: #252525 !important;
  box-shadow: 0 5px 20px 2px #474747;

  & .custom-svg {

    & path, & rect {
      fill: #1a1a1a;
    }
  }
}

.nav-tabs .nav-link {
  border-bottom-width: 3px !important;
  white-space: nowrap;
}

.nav-tabs:not(.no-padding) .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

.custom-icon {
  background: url('/assets/img/cloche.png') no-repeat;
  height: 15px;
  background-size: contain;
  vertical-align: middle;
}

.navbar-vertical.navbar-expand-md .navbar-brand {
  @media(min-width: 768px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  & .navbar-brand-img {
    max-height: 4rem;
  }
}

.nav-link .custom-svg svg {
  height: 15px;
  vertical-align: initial;
}

.card-header-search {
  border: 1px solid #b4b4b4;
}

.order-number {
  font-size: 3rem;
}

.badge {
  &.new {
    background-color: $success;
  }

  &.preparing {
    background-color: $primary;
  }

  &.ready {
    background-color: $info;
  }

  &.readytodeliver {
    background-color: #24798F;
    color: $white;
  }

  &.delivering {
    background-color: #ffd800;
  }

  &.arrived {
    background-color: #92a4a9;
  }

  &.delivered {
    background-color: #b1ff00;
  }

  &.completed {
    background-color: #007bff;
  }

  &.canceled {
    background-color: $danger;
  }
}

/*
  body * {
   // display: none!important;
  }
  .card-order, .card-order * {
    //display: block!important;
  }
  .card-order {
    position: absolute;
    left: 0;
    top: 0;
  }

  .main-content, .container-fluid, .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-fluid, .row {
    margin: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }


  .card-order, .card-order * {
    visibility: visible;
  }

  .card-order {
    width: 155px;
    padding: 0 !important;

    * {
      font-size: 12px;
      color: #000;
    }

    h1 {
      font-size: 25px;
    }

    h2 {
      font-size: 20px;
    }

    .row:nth-child(1) {
      margin: 0 !important;

      .mb-5 {
        margin-bottom: 1rem !important;
      }
    }

    .detail-order div:nth-child(1) {
      margin-bottom: 1rem !important;
    }

    .detail-order div, h4 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
    }
  }
*/

@media print {
  .main-content {
    margin: 0 !important;
  }

  nav {
    display: none !important;
  }

  html, body, #root {
    height: 100%;
  }

  .main-content, .container-fluid, .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-fluid, .row, .col, .col-12 {
    margin: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .card-order {
    padding: 0 !important;
    border: none;
    box-shadow: none;
    width: 100%;
    max-width: 100vw;
    margin: 0 0 500px 0 !important;

    * {
      font-size: 80pt;
      color: #000;
      page-break-inside: avoid;
    }

    html, body, table {
      height: auto;
    }

    h1, .h1 {
      font-size: 110pt !important;
    }

    h2, .h2 {
      font-size: 140pt !important;
    }

    .h3 {
      font-size: 90pt !important;
    }

    .h4 {
      font-size: 70pt !important;
    }

    .client-info * {
      font-size: 60pt !important;
      margin-bottom: 2rem !important;
    }

    .email * {
      font-size: 60pt !important;
      margin-bottom: 1rem !important;
    }

    .row:nth-child(1) {
      margin: 0 !important;

      .mb-5 {
        margin-bottom: 1rem !important;
      }
    }

    .detail-order div, h4 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
    }

    img {
      max-width: inherit;
      width: inherit;
    }

    td,
    th,
    tr,
    table, {
      border-top: 1px solid black;
      border-collapse: collapse;
      padding: 0 !important;
    }

    th, tr, table, th *, tr * {
      font-size: 50pt !important;
    }

    table td, table td * {
      font-size: 70pt !important;
    }

    > .row {
      margin-bottom: 5rem !important;

      > .col-12 {
        margin-bottom: 5rem !important;
      }
    }

    > .row:last-child {
      padding-bottom: 10rem !important;
    }
  }
}

.toast-notification {
  position: absolute;
  left: 10px;
  top: 0;
  right: 25px;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}

button .spinner-border {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
}
