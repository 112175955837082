//
// user-variables.scss
// Use this to overwrite Bootstrap and Dashkit variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;
$primary: #EEB540;
$navbar-dark-heading-color: #EEB540;
$navbar-dark-bg: #252525;
$navbar-dark-color: #fff;
$navbar-dark-active-color: #252525;
