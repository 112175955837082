//
// root.scss
// Extended from Bootstrap
//

html {
  height: 100%;
}

body, #root {
  min-height: 100%;
}
